.contact{
    background-color: #172dd1;
    padding: 50px 0;
    text-align: center;
    margin-top: 50px;
    margin-right: 100px;
    margin-left: 100px;
}
.contact h1{
    color: #fff;
    font-size: 40px;
    font-weight: 700;
    margin-bottom: 30px;
}
.contact input{
    width: 60%;
    padding: 20px;
    outline: none;
    border: none;
    margin-bottom: 20px;
    border-radius: 5px;
}

.contact textarea{
    width: 60%;
    padding: 20px;
    outline: none;
    border: none;
    margin-bottom: 20px;
    border-radius: 5px;
    resize: none;
}

.ReCAPTCHA{
    width: 60%;
    padding: 20px;
    outline: none;
    border: none;
    
    margin-bottom: 20px;
    border-radius: 5px;
    resize: none;
}

/* contact.css */
.submitButton {
    background-color: #4caf50; /* Green background color */
    color: white; /* White text color */
    padding: 10px 15px; /* Padding inside the button */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Cursor on hover */
  }
  
@media(max-width:800px){
    .contact{
        margin-right: 50px;
        margin-left: 50px;
    }
    .contact h1{
        font-size: 30px;
    }
    .contact input{
        width: 80%;
    }
    .contact textarea{
        width: 80%;
    }
    .ReCAPTCHA{
        width: 80%;
    }
    .submitButton{
        width: 80%;
    }
}

@media (max-width:)