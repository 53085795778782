.home {
  background-color: #fff;
}

.banner img {
  margin-top: 80px;
  margin-left: 10px;
  height: 350px;
  width: 100%; /* Adjusted width for responsiveness */
}

.about-t {
  color: blueviolet;
}

.about-p {
  color: cadetblue;
}

.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
  justify-content: space-between;
}

.footer-column {
  flex: 1;
  margin-right: 20px; /* Adjust margin as needed */
  margin-bottom: 20px; /* Add margin between columns */
}

#ad {
  margin-top: 60px;
}

.footer-column img {
  height: auto; /* Allow images to scale based on container width */
  width: 100%; /* Make images responsive within columns */
  margin-top: 20px; /* Adjusted margin for better spacing */
}

@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.about {
  display: flex;
  justify-content: space-around; /* Adjust spacing between columns */
  align-items: center;
  flex-wrap: wrap; /* Allow columns to wrap on smaller screens */
}

.column {
  flex: 1;
  max-width: 400px; /* Set a maximum width for each column */
  padding: 20px;
  margin: 10px;
  border: 1px solid #ccc; /* Add a border for better separation */
}

.column img {
  width: 100%; /* Make images responsive within the column */
  margin-bottom: 10px; /* Add margin below images */
}

@media (max-width: 650px) {
  .about {
      flex-direction: column; /* Stack columns on smaller screens */
  }

  .column {
      max-width: 100%; /* Full width for columns on smaller screens */
  }
}

